'use strict';

/*global Modernizr */
import store from './_store';
import helpers from './_helpers';
import ResponsiveDebug from './class/ResponsiveDebug';
import Collapsible from './class/Collapsible';
import ScrollAnchor from './class/ScrollAnchor';
import ShowPassword from './class/ShowPassword';
import ClearInput from './class/ClearInput';
import DetectBrowser from './class/DetectBrowser';
import Cookies from './class/Cookies';
import BannerMessages from './class/BannerMessages';
// import ValidForm from './class/ValidForm';
// import AnimatedLabelField from './class/AnimatedLabelField';
//import Colorbox from './class/Colorbox';
// import DatePicker from 'air-datepicker/dist/js/datepicker.min'

/**
 *
 * App
 * Main Controller
 *
 * @author acti
 * (vincegobelins, mha, efr, ...)
 */

const app = {
    init: function () {
        if ($('.onlyMarmite').length) {
            console.warn('/!\\ If you see this warning message, it means that your are in Marmite Styleguide.\n' +
                'If it\'s not the case, it means that someone has forgot to remove the class .onlyMarmite in dev template\n' +
                'and many js dev code won\'t work properly. :\'(');
        }

        this.bindUI();

        /*--- initialisation des tailles de viewport ---*/
        store.currentWidth = store.wWidth = helpers.viewport().width;
        store.currentHeight = store.wHeight = helpers.viewport().height;

        store.wScroll = $(window).scrollTop();

        let self = this;


        /*--- responsive-debug ---*/
        let responsiveDebug = new ResponsiveDebug();

        /*--- detectBrowser ---*/
        let detectBrowser = new DetectBrowser();

        /*--- Validation Form ---*/
        // let validForm = new ValidForm({
        //     /*container: '.valid-form',
        //     fieldContainer: '.form-field',
        //     validClass: 'as--valid',
        //     invalidClass: 'as--invalid',
        //     msgErrorClass: 'form-msg-error',*/
        // });

        /*--- cookies ---*/
        store.cookies = new Cookies();

        /*--- Banner messages (cookies consent / warnig / news...) ---*/
        const messagesBanner = new BannerMessages(/*{
      //caping: 3,
    }*/);

        /*--- Skip links ---*/
        let skip = new Collapsible({
            container: '.skip',
            cta: '.skip-cta',
            className: 'as--focused',
            optionHover: true
        });

        /*--- colorbox ---*/
        /*let colorbox = new Colorbox();*/

        /*--- animation scroll ---*/
        /* Use '.js-scroll-anchor' class to trigger smooth anchor scroll*/
        store.scrollAnchor = new ScrollAnchor();

        /*--- password ---*/
        let showPassword = new ShowPassword();

        /*--- clear input ---*/
        let clearInput = new ClearInput();

        /*--- animated label ---*/
        // let form = new AnimatedLabelField();

        // responsive
        self.onResize();
    },

    checkMobile: function () {
        if (/Android|webOS|iPhone|iPad|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent)) {
            return true;
        }
    },

    onResize: function () {
        let self = this;
    },

    onScroll: function () {
        let self = this;
    },

    bindUI: function () {
        let self = this;

        if (Modernizr.mq('only all')) {
            $(window).on('resize', function () {
                store.wWidth = helpers.viewport().width;
                store.wHeight = helpers.viewport().height;
                if (store.currentHeight !== store.wHeight || store.currentWidth !== store.wWidth) {
                    store.currentHeight = store.wHeight;
                    store.currentWidth = store.wWidth;
                    /*--- timer pour le redimensionnement d'ecran ---*/
                    clearTimeout(store.timerResponsive);
                    store.timerResponsive = setTimeout(self.onResize.bind(self), 300);
                }
            });
        }

        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                self.onResize();
            }
        };

        /*--- fonctions au scroll ---*/
        $(window).on('scroll', function () {
            store.wScroll = $(window).scrollTop();

            self.onScroll();
        });

        /*-- Select2 --*/
        $(document).ready(() => {
            $('.select2').select2();
        })

        // ==================== CUSTOM SCRIPT =====================//

        /**
         * Menu logic
         */
        let toggleMenuSelector = '.js--toggle-menu';

        $(toggleMenuSelector).on('click', function () {
            if ($('#hamburger').hasClass('as--active') != true) {
                $('#hamburger').toggleClass('as--active');
                $('.header-menu').toggleClass('as--active');
                $('.main-menu').addClass('as--active')
                $(toggleMenuSelector).toggleClass('as--active');
            } else {
                $('#hamburger').removeClass('as--active');
                $('.header-menu').removeClass('as--active');
                $('.main-menu').removeClass('as--active');
                $(toggleMenuSelector).removeClass('as--active');

            }
        });

        /**
         *  page diploma - step 1 : other diploma
         */
        let lastDiploma = document.getElementById('candidate_diploma_form_laststudydiploma');
        if (lastDiploma && lastDiploma.length > 0) {
            let siblingField = lastDiploma.closest('.form-field').nextElementSibling;

            $(lastDiploma).on('change', (e) => {
                var value = lastDiploma.options[lastDiploma.selectedIndex].value;
                if (!value) {
                    $(siblingField).removeClass('as--hidden');
                } else {
                    $(siblingField).addClass('as--hidden');
                }
            })
        }

        /**
         *  page diploma - step 1 : other period
         */
        let lastDiplomaPeriod = document.getElementById('candidate_diploma_form_laststudyperiod');
        if (lastDiplomaPeriod && $(lastDiplomaPeriod).length > 0) {
            let siblingField = lastDiplomaPeriod.closest('.form-field').nextElementSibling;
            $(lastDiplomaPeriod).click(function () {
                if ($(lastDiplomaPeriod).find('#candidate_diploma_form_laststudyperiod_2').is(':checked')) {
                    siblingField.style.visibility = 'visible';
                } else {
                    siblingField.style.visibility = 'hidden';
                }
            });
        }

        /**
         *  page diploma - step 2 : other diploma
         */
        document.querySelectorAll('.other-choice').forEach(function (el) {
            el.addEventListener('change', function (e) {
                let $sibling = $(e.currentTarget)[0].closest('.input-checkbox').nextElementSibling;
                if ($($sibling).length > 0) {
                    if ($($sibling).hasClass('as--hidden')) {
                        $($sibling).removeClass('as--hidden');
                    } else {
                        $($sibling).addClass('as--hidden');
                    }
                }
            });
        });


        /**
         *  Dropzone
         */
        jQuery(document).ready(function () {
            $('.my-dropzone').dropzone({
                init: function () {
                    let myDropzone = this;
                    myDropzone.previewsContainer = $(myDropzone.element).find('.previewContainer-dropzone').get(0);
                    if ($(this.element).attr('disabled')) {
                        this.disable();
                    }
                    this.on("addedfile", function (file) {
                        let myDropzone = this;
                        // let previewContainer = $(myDropzone.element).find('.previewContainer-dropzone').get(0);
                        // let newUrl = $(previewContainer)[0].dataset.filesUrls;
                        //
                        // if (newUrl.length > 0){
                        //     $(previewContainer).find('.file-url')[0].href = newUrl;
                        // }
                        $(myDropzone.element).addClass('dz-started'); //

                        if (file.allowedToDelete == undefined || file.allowedToDelete == true) {
                            file._removeLink = Dropzone.createElement(`<a class="dz-remove" href="javascript:undefined;" data-dz-remove>${this.options.dictRemoveFile}</a>`);
                            file.previewElement.appendChild(file._removeLink);
                        }
                        if (file.isInvalid) {
                            myDropzone.emit('error', file, "Pièce non conforme");
                        }

                        let removeFileEvent = e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (file.status === Dropzone.UPLOADING) {
                                return Dropzone.confirm(this.options.dictCancelUploadConfirmation, () => this.removeFile(file));
                            } else {
                                if (this.options.dictRemoveFileConfirmation) {
                                    return Dropzone.confirm(this.options.dictRemoveFileConfirmation, () => this.removeFile(file));
                                } else {
                                    return this.removeFile(file);
                                }
                            }
                        };

                        for (let removeLink of file.previewElement.querySelectorAll("[data-dz-remove]")) {
                            removeLink.addEventListener("click", removeFileEvent);
                        }
                    });

                    this.on("complete", function (file) {
                        let _aElement = $(file.previewElement).find('.file-url');
                        if (file.link) {
                            _aElement.attr('href', file.link);
                        }
                        if (file.xhr) {
                            file.link = $.parseJSON(file.xhr.response).link;
                            _aElement.attr('href', file.link);
                        }
                    });

                    this.on("removedfile", function (file) {
                        $.ajax({
                            url: file.link,
                            method: "delete",
                        }).fail(function (event) {
                            myDropzone.emit('error', file, "la suppression à échoué");
                        });
                    });
                    this.on('error', function (file, data) {
                        let myPreview = $(file.previewElement)[0];
                        if ($(myPreview).length > 0) {
                            let warning = $(myPreview).find('.dz-icon-file-upload-warning');
                            warning.removeClass('as--hidden');
                        }
                        if (data.detail) {
                            this.emit('error', file, data.detail);
                        }
                    });

                    let existings = $(this.element).data('existing');
                    existings.forEach(function (mockFile) {
                        let callback = null; // Optional callback when it's done
                        let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
                        let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
                        myDropzone.files.push(mockFile);
                        myDropzone.displayExistingFile(mockFile, mockFile.src, callback, crossOrigin, resizeThumbnail);
                    });

                },
                acceptedFiles: 'image/jpeg,image/png,application/pdf',
                dictRemoveFile: '×',
                dictResponseError: 'Une erreur est survenue.',
                dictInvalidFileType: 'Fichier invalide.',
                dictFileTooBig: 'Fichier trop lourd.',
                dictCancelUpload: '×',
                previewTemplate: $('#dropzone-tpl').html()
            });
        });

        /**
         * Slick - interviews
         */



        $('.slick-wrapper-multi').not('.js-done').addClass('js-done').each(function () {
            var $currentSlider = $(this).find('.slider-multi ');
            var $sliderControlsPrev = $(this).find('.as--slick-prev');
            var $sliderControlsNext = $(this).find('.as--slick-next');


            $($currentSlider).slick({
                'dots': false,
                infinite: false,
                draggable: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: $sliderControlsPrev[0],
                nextArrow: $sliderControlsNext[0],
                responsive: [
                    {
                        breakpoint: 1700,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 609,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                    ]
            });
        });

        let $interviewCheckboxesContainer = document.getElementById('candidate-interview_cases');
        if ($($interviewCheckboxesContainer) != null && $($interviewCheckboxesContainer).length > 0) {

            let $inputContainer = $interviewCheckboxesContainer.querySelectorAll('.input-radio');
            $inputContainer.forEach((form) => {
                var $input = $(form).find('input');
                $($input).on('click', () => {

                    let $formName = $input[0].dataset.sectionForm;
                    let $formContainer = document.getElementById($formName);

                    if ($input.prop('checked') == true) {
                        $($formContainer).removeClass('as--hidden');
                        let $formInputs  = ($formContainer).querySelectorAll('input');
                        $formInputs.forEach(($radio) => {
                            $($radio).prop('required', true);
                        })
                    } else {
                        $($formContainer).addClass('as--hidden');
                        let $formInputs  = ($formContainer).querySelectorAll('input');
                        $formInputs.forEach(($radio) => {
                            $($radio).prop('required', false);
                        })

                    }
                });
            });

            let requiredCheckboxes = $('.input-wrapper :checkbox[required]');
            $(requiredCheckboxes).on('change', () => {
                if (requiredCheckboxes.is(':checked')) {
                    requiredCheckboxes.removeAttr('required');
                } else {
                    requiredCheckboxes.attr('required', 'required');
                }
            })

        }

    }
};

app.init();

// global custom functions, they can be called from anywhere within the project (useful for the back-end developers)
let customFunctions = {
    // global custom function example
    // to call it from anywhere : global.customFunction.afterAjaxExample();
    /*afterAjaxExample: function() {
     helpers.resizeImg('.media-block-news');
     }*/
};
// exports the elements that need to be accessed from somewhere else (in the "global" standalone object, cf. gulpfile)
module.exports = customFunctions;
